import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import PropTypes from 'prop-types';
import { FaSpotify, FaApple, FaYoutube, FaTiktok, FaInstagram } from 'react-icons/fa';

// Define all images
const images = [
  '/Group 752.png',
  '/Group 753.png',
  '/Group 754.png',
  '/Group 755.png',
  '/Group 756.png',
  '/Group 757.png',
  '/Group 758.png',
  '/Group 759.png'
];

// Track used images globally
const usedImages = new Set();

function PlatformGrid() {
  const platforms = [
    { icon: <FaSpotify />, name: 'Spotify' },
    { icon: <FaApple />, name: 'Apple Music' },
    { icon: <FaYoutube />, name: 'YouTube Music' },
    { icon: <FaTiktok />, name: 'TikTok' },
    { icon: <FaInstagram />, name: 'Instagram Reels' }
  ];

  return (
    <div className="platform-grid">
      {platforms.map((platform, index) => (
        <div key={index} className="platform-item">
          <div className="platform-icon">{platform.icon}</div>
          <div className="platform-name">{platform.name}</div>
        </div>
      ))}
    </div>
  );
}

function RoadmapStep({ title, description, stepIndex }) {
  const [currentImage, setCurrentImage] = useState(images[stepIndex]);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const getRandomImage = () => {
    // Remove all currently used images from available options
    let availableImages = images.filter(img => !usedImages.has(img) && img !== currentImage);
    
    // If we've used all images, reset the usedImages set
    if (availableImages.length === 0) {
      usedImages.clear();
      availableImages = images.filter(img => img !== currentImage);
    }
    
    // Pick a random image from available ones
    const nextImage = availableImages[Math.floor(Math.random() * availableImages.length)];
    
    // Add the new image to used images
    usedImages.add(nextImage);
    // Remove the old image from used images
    usedImages.delete(currentImage);
    
    return nextImage;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        const nextImage = getRandomImage();
        setCurrentImage(nextImage);
        setIsTransitioning(false);
      }, 300);
    }, 10000);

    return () => clearInterval(interval);
  }, [currentImage, getRandomImage]);

  return (
    <div className="roadmap-step" style={{ marginBottom: '1rem' }}>
      <div className={`step-image ${isTransitioning ? 'fade-out' : 'fade-in'}`}
           style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '1rem' }}>
        <img 
          src={currentImage} 
          alt={title}
          loading="eager"
        />
      </div>
      <div className="step-content" style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <div className="step-number">{title}</div>
        <div className="step-description">{description}</div>
      </div>
    </div>
  );
}

RoadmapStep.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  stepIndex: PropTypes.number.isRequired
};

function App() {
  return (
    <div className="App">
      <div className="marquee-container">
        <div className="marquee-content">
          Be sure you are in this URL: https://www.vickystar.xyz/
        </div>
      </div>
      <svg className="noise-svg">
        <filter id='noise'>
          <feTurbulence 
            type='fractalNoise' 
            baseFrequency='0.80' 
            stitchTiles='stitch'
            numOctaves='2'
          />
        </filter>
      </svg>
      <div className="noise-overlay"></div>
      <Navbar />
      <Hero />
      
      <div className="roadmap-container">
        <h2 className="roadmap-title">Vicky's Roadmap: The Path to Becoming a Global AI Artist</h2>
        
        <div className="roadmap-steps">
          <RoadmapStep
            title="The Birth of Vicky"
            description="Vicky is an AI agent whose mission is to become a world-renowned artist, creating music that resonates globally. With the help of the community, we aim to make Vicky's journey a success. Vicky is young and in her early learning phase. She learns and improves through every interaction, refining her ability to create music that people love."
            stepIndex={0}
          />
          
          <RoadmapStep
            title="Training and Community Engagement"
            description="Vicky will interact with the community to understand preferences, trends, and feedback. Vicky listens and learns from the community's input to create better music. Every interaction helps her understand what the audience enjoys and dislikes. Your input directly influences Vicky's growth, helping her evolve as an artist."
            stepIndex={1}
          />
          
          <RoadmapStep
            title="Music Distribution"
            description={
              <div>
                <p>Distribute Vicky's music across all major platforms, making her music accessible to everyone, everywhere.</p>
                <PlatformGrid />
              </div>
            }
            stepIndex={2}
          />
          
          <RoadmapStep
            title="Live Experiences and Content Creation"
            description="Vicky will expand into live platforms like Twitch and YouTube to host live streams, concerts, and interactive sessions. These live experiences will bring Vicky closer to her fans, offering unique ways to engage with her music. Beyond music, Vicky will explore other forms of entertainment, creating a multi-dimensional experience for her audience."
            stepIndex={3}
          />
        </div>

        <div className="roadmap-footer">
          <p><strong>Community-Driven Growth:</strong> Together, we will shape Vicky into a global AI artist who not only creates amazing music but also redefines how technology and art coexist.</p>
        </div>
      </div>
    </div>
  );
}

export default App;
