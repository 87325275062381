import React from 'react';

function Navbar() {
  return (
    <nav className="navbar">
      <div className="marquee-container">
        <div className="marquee-content">
          <span>Be sure you are in this URL: https://www.vickystar.xyz/</span>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;