import React, { useEffect } from 'react';

const TwitterTimeline = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="twitter-widget">
      <a 
        className="twitter-timeline" 
        data-theme="dark"
        data-width="500"
        data-height="800"
        href="https://twitter.com/vicky_popstar"
        data-chrome="noheader nofooter transparent"
      >
        Tweets by Vicky
      </a>
    </div>
  );
};

export default TwitterTimeline;