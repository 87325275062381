import React from 'react';
import TwitterTimeline from './TwitterTimeline';

function Hero() {
  return (
    <div className="hero-wrapper">
      <div className="hero-main">
        <div className="hero-content">
          <div className="header-section">
            <div className="ca-banner">
              CA: 7JXwBQDSPQZH4gUy1RGY4Tm5atf8g9v1eNG3h5trrB7X
            </div>
            <h1 className="title-container">
              <span className="vicky-text">Vicky</span>
              <span className="ia-text">AI</span>
            </h1>
          </div>
          
          <div className="content-grid">
            <div className="text-content">
              <h2 className="hero-tagline">
                <span className="main-tagline">Redefining music in the digital age</span>
                <span className="sub-tagline">Crafting the future of AI artistry</span>
              </h2>
              <p className="hero-description" style={{ fontFamily: "'Sometype Mono', monospace" }}>
                Vicky is an AI-powered artist blending technology and creativity to create unique sonic experiences. 
                Through community interaction, she evolves and learns, shaping the future of digital music.
              </p>
              <a 
                href="https://jup.ag/swap/SOL-7JXwBQDSPQZH4gUy1RGY4Tm5atf8g9v1eNG3h5trrB7X" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="buy-token-link"
              >
                Buy $Vicky token
              </a>
            </div>
            
            <div className="hero-image">
              <img src="/VickyAIbanner.jpeg" alt="Vicky AI Banner" />
            </div>
          </div>

          <div className="info-banner">
            Vicky is an AI agent that uses ELIZA framework
          </div>
        </div>
      </div>
      <aside className="twitter-section">
        <TwitterTimeline />
        <div className="music-platforms">
          <h3 className="platforms-title">Listen Now on</h3>
          
          <div className="main-platforms">
            <a href="https://open.spotify.com/intl-es/artist/3HkVfuzPaoDoZ74MWD1uaT"
              className="platform-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-spotify"></i> Spotify
            </a>
            <a href="https://music.apple.com/us/artist/vicky-ai/1781470963" 
               className="platform-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-apple"></i> Apple Music
            </a>
            <a href="https://www.instagram.com/vicky_popstar_oficial/" 
              className="platform-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i> Instagram
            </a>
            <a 
              href="https://www.tiktok.com/@vicky_popstar?_t=8rfXbnc7MbY&_r=1" 
              className="platform-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-tiktok"></i> TikTok
            </a>
            <a 
              href="https://music.youtube.com/channel/UCPN6ZiZ1W0m15V1VJyXwuwA" 
              className="platform-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube"></i> YouTube Music
            </a>
            <a href="https://music.amazon.com/artists/B0DNX6RNF7/vicky-ai" 
               className="platform-link"
               target="_blank"
               rel="noopener noreferrer"
            >
              <i className="fab fa-amazon"></i> Amazon Music
            </a>
          </div>

          <div className="additional-platforms">
            <p className="platforms-subtitle">Available on:</p>
            <p className="platform-list">
              ByteDance stores • Pandora • Deezer • Tidal • iHeartRadio • Claro Música • 
              Saavn • Boomplay • Anghami • NetEase • Tencent • Qobuz • Joox • 
              Kuack Media • Adaptr • Flo • MediaNet
            </p>
          </div>
        </div>
      </aside>
    </div>
  );
}

export default Hero;